import Constants from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { FetchErrorLogger, FetchCatchHandler } from '../middlewares';
import { AddSyncTimestamp, CheckReSync } from './LastSyncActions';
import { GetPlantPersonnel } from './PersonnelActions';
import { GetPlantCAD } from './CorrugatorAdhesiveDataActions';
import { GetPlantDSI } from './DesiredSpeedImprovementsActions';
import { GetPlantLines } from './LineActions';
import { GetPlantMachines } from './MachineActions';
import { GetPlantMachineFlutes } from './MachineFlutesActions';
import { GetPlantMachineVessels } from './MachineVesselsActions';
import { GetPlantFormulas } from './FormulaActions';
import { GetPlantFormulaSteps } from './FormulaStepActions';
import { GetKeyItemsByPlantID } from './KeyItemActions';
import { GetVisitsByPlantID } from './VisitActions';
import { OFFLINE_STATUS_CHANGED } from "@redux-offline/redux-offline/lib/constants";
import { GetBaselinesByPlantID } from './BaselineActions';
import { GetContImproveList } from './ContinuousImprovementAction';
import { GetStarchGRPByPlantID } from './StarchGraphAction';
export const GetPlantsData = () => {
    console.log('DataActions.js GetPlantsData Called');
    return (dispatch, getState) => {
        console.log('DataActions.js GetPlantsData Thunk Called');
        const state = getState();
        if (state.offline.online && getState().Plants.length == 0) {
            //No Data Get Data
            let fetchTask = fetch('api/Plants/GetPlants', { headers: { 'Authorization': 'Bearer ' + state.Auth.token } })
                .then(FetchErrorLogger("GetPlantsData", null, dispatch))
                .then(response => response.json())
                .then(plants => {
                    console.log("Got Plants Links", plants);
                    dispatch({ type: Constants.Actions.ReceivePlants, data: plants })
                })
                .catch(FetchCatchHandler("GetPlantsData", null, dispatch))
                ;
        }
    };
};

export const GetPlantData = (getid, fromCall) => {

    console.log('DataActions.js GetPlantData Called', getid)
    return (dispatch, getState) => {
        //offline.online
        const state = getState();
        if (state.offline.online && CheckReSync(getid, state.LastSync, 900000)) {
            let fetchTask = fetch('api/Plants/' + getid, { headers: { 'Authorization': 'Bearer ' + state.Auth.token} })
                .then(FetchErrorLogger("GetPlantData", getid, dispatch))
                .then(response => response.json())
                .then(cachePlant => {
                    console.log("Got Plant data", cachePlant);
                    dispatch({ type: Constants.Actions.AddPlantCache, data: cachePlant });
                    //Get Child Data
                    dispatch(GetPlantPersonnel(getid));
                    dispatch(GetPlantCAD(getid));
                    dispatch(GetPlantDSI(getid));
                    dispatch(GetPlantLines(getid));
                    dispatch(GetPlantMachines(getid));
                    dispatch(GetPlantMachineFlutes(getid));
                    dispatch(GetPlantMachineVessels(getid));
                    dispatch(GetPlantFormulas(getid));
                    dispatch(GetPlantFormulaSteps(getid));
                    dispatch(GetVisitsByPlantID(getid));
                    dispatch(GetBaselinesByPlantID(getid));
                    dispatch(GetStarchGRPByPlantID(getid));
                    dispatch(GetKeyItemsByPlantID(getid));
                    dispatch(AddSyncTimestamp(getid,'Plant'));
                    dispatch(GetContImproveList()); 
                })
                .catch(FetchCatchHandler("GetPlantData", getid, dispatch))
        }
    }
}
export const UpdatePlant = (updPlant) => {
    console.log('DataActions.js UpdatePlant Called');
    console.log('put check __________________________________________________________________________  26');
    return (dispatch, getState) => {
        //TODO: Add data check
        fetch('api/Plants/' + updPlant.id, {
            body: JSON.stringify(updPlant),
            headers: { 'content-type': 'application/json' },
            method: 'PUT'
        })
            .then(response => {
                if (response.status !== 204) {
                    console.error('Update API Error');
                    alert('bad API call');
                } else {
                    dispatch({ type: Constants.Actions.UpdatePlant, data: updPlant });
                }
            })
            .catch(error => { console.error('UpdatePlant Error', error); alert('UpdatePlant API call error'); })
    }
};

export const ClearData = () => { return { type: Constants.Actions.Plant_ClearData }; };
