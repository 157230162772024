import Constants from '../constants';
import { GetAllActionItems, ClearData as ActionClearData } from './ActionItemActions';
import { ClearData as ActionLinkClearData } from './ActionItemLinkActions'; 
import { GetFlutes, GetVessels, ClearData as MetaClearData } from './MetaDataActions';
import { AddSyncTimestamp, CheckReSync,ClearLastSyncs } from './LastSyncActions';
import { GetVisits } from './VisitActions';
import { GetPlantsData } from './PlantActions';
import { enqueueSnackbar } from './NotificationActions';
import { ClearData as CADClearData } from './CorrugatorAdhesiveDataActions'
import { ClearData as DSIClearData } from './DesiredSpeedImprovementsActions';
import { ClearData as FormulaClearData } from './FormulaActions';
import { ClearData as FormChangeClearData } from './FormulaChangeActions';
import { ClearData as FormStepClearData } from './FormulaStepActions';
import { ClearData as LinesClearData } from './LineActions';
import { ClearData as MachineClearData } from './MachineActions';
import { ClearData as MFluteClearData } from './MachineFlutesActions';
import { ClearData as MVesselClearData } from './MachineVesselsActions';
import { ClearData as PersonnelClearData } from './PersonnelActions';
import { ClearData as PlantsClearData } from './PlantActions';
import { ClearData as SErrorClearData } from './SyncErrorActions';
import { ClearData as VisitClearData } from './VisitActions';
import { ClearData as AttachmentClearData } from './AttachmentActions';
import { ClearIVData } from './IngredientVerificationActions';
import { ClearData as HVClearData } from './HeatVesselActions';
import { ClearData as KeyItemsClearData } from './KeyItemActions';
import { ClearData as BaselilneClearData } from './BaselineActions';
import { RESET_STATE } from "@redux-offline/redux-offline/lib/constants";
import { ClearReports, GetReports } from './ReportActions';
import { ClearAllCheckListData } from './ChecklistActions';
import { ClearGlueLineData } from './GlueLineActions';
import { ClearGlueRollData } from './GlueRollActions';
import { ClearInventoryData } from './InventoryActions';
import { ClearMeasurements } from './MeasurementActions';
import { ClearStarchKitchen } from './StarchKitchenActions';
import { clearAllNotifications } from './NotificationActions';
import { ClearThermalsData } from './ThermalImageActions';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { log } from 'logrocket';
import { ClearContImproveData, GetContImproveList } from './ContinuousImprovementAction';
import { ClearStartchGrpRecords } from './StarchGraphAction';
export const GetAllData = () => {
    console.log('DataActions.js GetAllData Called');
    return (dispatch, getState) => {
        const state=getState();
        if (state.offline.online && CheckReSync("AllData", state.LastSync, 900000)) {
            dispatch(GetPlantsData());
            dispatch(GetVessels());
            dispatch(GetVisits());
            dispatch(GetReports());   
            dispatch(AddSyncTimestamp("AllData","AllData")); 
        }
        
    };
};

export const CloseSyncSnack = () => {
    return {
        type: Constants.Actions.CloseSyncSnack
    };
}
export const AddErrorNotification = (errorMsg, source) => {
    alert("Error Notification Not Implemented! -" + errorMsg);
    return {
        type:"NotImplemented"
    }
}
export const ReSync = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch(ClearLastSyncs());
        dispatch(GetAllData());
    }
}
export const WipeAndRestore = () => {
    return (dispatch, getState) => {
        const state = getState();
        if (state.offline.online) {
            dispatch(enqueueSnackbar({
                message: 'ReSync Started',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'info'
                }
            }));
            //Data Clear 
            dispatch({ type: RESET_STATE });
            dispatch(ClearLastSyncs());
            dispatch(CADClearData());
            dispatch(DSIClearData());
            dispatch(FormulaClearData());
            dispatch(FormChangeClearData());
            dispatch(FormStepClearData());
            dispatch(LinesClearData());
            dispatch(MachineClearData());
            dispatch(MFluteClearData());
            dispatch(MVesselClearData());
            dispatch(MetaClearData());
            dispatch(PersonnelClearData());
            dispatch(PlantsClearData());
            dispatch(ClearThermalsData());
            dispatch(VisitClearData());
            dispatch(ActionClearData());
            dispatch(ActionLinkClearData());
            dispatch(AttachmentClearData());
            dispatch(ClearIVData());
            dispatch(HVClearData());
            dispatch(KeyItemsClearData());
            dispatch(BaselilneClearData());
            dispatch(ClearAllCheckListData());
            dispatch(ClearGlueLineData());
            dispatch(ClearGlueRollData());
            dispatch(ClearInventoryData());
            dispatch(ClearReports());
            dispatch(ClearMeasurements());
            dispatch(ClearStarchKitchen());
            dispatch(ClearContImproveData());
            dispatch(ClearStartchGrpRecords);
            

            dispatch(GetAllData());
            dispatch(clearAllNotifications());
            dispatch(enqueueSnackbar({
                message: 'ReSync Complete',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success'
                }
            }));
        } else {
            dispatch(enqueueSnackbar({
                message: 'Must be online to trigger ReSync',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error'
                }
            }));
        }
    };
}
export const UpdateAction = (actionType, name, data, newValue) => {

    const oldValue = data[name];
    const updateObj = { id: data.id };
    updateObj[name] = newValue;
    const updatePayload = {
        updateObj: updateObj,
        name: name,
        oldValue: oldValue,
        newValue: newValue,
        action: actionType,
        id: data.id,
        source: data
    }
    let updType = "";
    let updCommitType = "";
    let updRollbackType = "";
    let url = "";
    if (typeof actionType == "string") {
        updType = Constants.Actions[actionType];
        updCommitType = Constants.Actions[actionType + '_Commit'];
        updRollbackType = Constants.Actions[actionType + '_Rollback'];
        url = Constants.URLs[actionType];
    } else {
        //Object
        updType = actionType.Update;
        updCommitType = actionType.Update_Commit;
        updRollbackType = actionType.Update_Rollback;
        const tmpType = actionType.Update.substr(0, actionType.Update.indexOf('_'));
        url = Constants.URLs[tmpType];
    }
    
    return {
        type: updType
        , payload: updatePayload
        , meta: {
            offline: {// the network action to execute:
                effect: {
                    url: url + data.id, method: 'PUT', body: JSON.stringify(updateObj), headers: { 'content-type': 'application/json' }
                },
                // action to dispatch when effect succeeds:
                commit: {
                    type: updCommitType, meta: { updatePayload }
                },
                // action to dispatch if network action fails permanently:
                rollback: {
                    type: updRollbackType, meta: { updatePayload }
                }
            }
        }
    };
};