import Constants from '../../constants';
//Libraries
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
//MUI
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LaunchIcon from '@mui/icons-material/Launch';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintIcon from '@mui/icons-material/Print';
import makeStyles from '@mui/styles/makeStyles';
import companyLogo from "../../../src/images/companyLogo.png";
import LargeLogo from "../../../src/images/LargeLogo.png";
//Components
import { DataForm, DataTextBox, DataDate, DataSelect, DataNumber, DataFormContext } from '../FormManager';
import { useReportUser, usePlantDataFromVisitID, useVisitData, useReportName } from '../../misc/UseFuncs';
import { PlantLink } from '../MetaData/Links';
import { usePlantIDFromVisitID } from '../MetaData/PlantOptions';
import GetEntity from '../../misc/GetEntity';

//Actions
import { DeleteGlueLine, LoadGlueLine, AddGlueLineMachinesByLineID, SetGlueLineName } from '../../actions/GlueLineActions';
import { GetPlantMachines } from '../../actions/MachineActions'
import { GetPlantMachineVessels } from '../../actions/MachineVesselsActions';
import { GetPlantMachineFlutes } from '../../actions/MachineFlutesActions';
import { enqueueSnackbar, closeSnackbar } from '../../actions/NotificationActions';
import { ConfirmAction } from '../../actions/ConfirmActionActions';
import { UpdateAction } from '../../actions/DataActions';
import { IconButton, Typography } from '@mui/material';
import { v5 } from 'uuid';
import { Padding } from '@mui/icons-material';
import { DeleteContImprove, UpdateContImprove } from '../../actions/ContinuousImprovementAction';

const constants = Constants;
const useStyles = makeStyles((theme) => ({
    header: {
        borderBottom: 'solid'
    },
}));
function formatDateTodate(date) {
   if (date !== '') {
    const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear() % 100; // Get last two digits of the year

    // Construct the formatted date string
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
   } else {
    return ''
   }
}
function formatDate(date) {
    const month = new Date(date).getMonth() + 1; // Month is zero-based, so we add 1
    const day = new Date(date).getDate();
    const year = new Date(date).getFullYear() % 100; // Get last two digits of the year

    // Construct the formatted date string
    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}

const ContinuousImprovementView = props => {
    const params = useParams();
    const ContImpID = params.ContImpID;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [Pid, setPid] = useState(0);
    const [BaselineData, setBaselineData] = useState(null)
    const [wasteData, setwasteData] = useState(null)
    const [DowntimeData, setDowntimeData] = useState(null)
    const [ConsumptionData, setConsumptionData] = useState(null)
    const [ProductionData, setProductionData] = useState(null)
    const [TrainingData, setTrainingData] = useState(null)
    const [FoorterData, setFoorterData] = useState(null)
    const [PlantName, setPlantName] = useState('');
    const [PlantDObj, setPlantDObj] = useState(null);
    const [projectSummary, setprojectSummary] = useState([]);
    const [BaselineDObj, setBaselineDObj] = useState(null);
    const [FileValue, setFileValue] = useState(null);
    const [FileBase64Str, setFileBase64Str] = useState('');
    const [FileBase64FileName, setFileBase64FileName] = useState('');
    const [ReadyForDownload, setReadyForDownload] = useState(false);
    const [UpdateFile, setUpdateFile] = useState(false);
    const initialValues = { fromDetailUserUploadedFileData: '' };
    // const [printData, setprintData] = useState(null);
    const [VisitType, setVisitType] = useState('');
    const { DetailsObj, Plist, Vlist, Baselines, userObj } = useSelector(state => {
        return {
            DetailsObj: state.ContImprove.find(gr => gr.id == ContImpID),
            Plist: state.Plants,
            Vlist: state.Visits,
            Baselines: state.Baselines,
            userObj: state.Auth.userData
        };
    }, shallowEqual);

    const plantID = usePlantIDFromVisitID(DetailsObj ? DetailsObj.visitID : null);
    const user = userObj ? userObj.fullName : ''
    const VisitObj =
        useEffect(() => {
            setPid(plantID)
            let Pdata = Plist.find(p => p.id == plantID);
            let Vdata = Vlist.find(v => v.id == DetailsObj.visitID);
            if (Pdata) {
                setPlantName(Pdata.name)
                setPlantDObj(Pdata)
            }
            if (Vdata) {
                setVisitType(Vdata.type)
            }
        }, [plantID])
        const handleDel = () => {
            const visitID = params.VisitID;
            dispatch(ConfirmAction("Do you want to delete this Continuous Improvement?", DeleteContImprove(DetailsObj), null,
                (confirmed) => {
                    if (confirmed) {
                        // navigate(-1);
                        navigate('/VisitOverview/' + DetailsObj.visitID);
                    }
                }));
        }
    const handleClose = () => {
        // navigate(-1);
        navigate('/VisitOverview/' + DetailsObj.visitID);
    }
    const handleEdit = () => {
        // navigate(-1);
        navigate('/ContinuousImprovement/' + DetailsObj.visitID + '/' + DetailsObj.id);
    }

    const commonStyles = {
        bgcolor: 'background.paper',
        borderColor: 'text.primary',
        m: 1,
        p: 1,
        border: 1,
        borderRadius: 1,
    };
    const cellText = {
        p: 1
    };

    console.log('printData', DetailsObj);
    useEffect(() => {
        if (FoorterData !== null) {
            if (FoorterData.fromDetailUserUploadedFileName !== '' && FoorterData.fromDetailUserUploadedFileName !== null && FoorterData.fromDetailUserUploadedFileName && FoorterData.fromDetailUserUploadedFileData) {
                setReadyForDownload(true)
                setFileBase64Str(FoorterData.fromDetailUserUploadedFileData)
            }
        }
    }, [FoorterData])
    useEffect(() => {
        let selectedBaseline = Baselines.find(p => p.id === DetailsObj.baselineID)
        if (selectedBaseline && DetailsObj) {
            let Pdata = Plist.find(p => p.id == plantID);
            setBaselineDObj(selectedBaseline)
            const BaselineData1 = GetBaseLineData(DetailsObj, selectedBaseline);
            setBaselineData(BaselineData1)
            const wasteData1 = GetWasteObj(DetailsObj, selectedBaseline);
            setwasteData(wasteData1)
            const DowntimeData1 = GetDownShiftData(DetailsObj, selectedBaseline);
            setDowntimeData(DowntimeData1)
            const ConsumptionData1 = GetConsumptionObj(DetailsObj, selectedBaseline);
            setConsumptionData(ConsumptionData1)
            const ProductionData1 = GetProductionObj(DetailsObj, selectedBaseline);
            setProductionData(ProductionData1)
            const TrainingData1 = GetTrainingData(DetailsObj);
            setTrainingData(TrainingData1)
            const FoorterData1 = GetFooterData(DetailsObj, wasteData1, TrainingData1, DowntimeData1, ConsumptionData1, ProductionData1, Pdata);
            setFoorterData(FoorterData1)
            // console.log("BaselineData1----------------", BaselineData1);
            // console.log("DetailsObj----------------", DetailsObj);
            // console.log(" wasteData1----------------", wasteData1);
            // console.log("TrainingData1----------------", TrainingData1);
            // console.log("DowntimeData1----------------", DowntimeData1);
            // console.log("ConsumptionData1------>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>----------", ConsumptionData1);
            // console.log("ProductionData1----------------", ProductionData1);
            // console.log("FoorterData1----------------", FoorterData1);
            // console.log("DetailsObj.projectSummary----------------", DetailsObj.projectSummary);
            setprojectSummary(DetailsObj.projectSummary !== '' && DetailsObj.projectSummary !== null ? JSON.parse(DetailsObj.projectSummary) : [])

        }
        console.log('ddd', PlantDObj);
    }, [DetailsObj])
    const handlePrint = () => {
        navigate('/ContinuousImprovementPrint/' + ContImpID);
    }
    const formatnum = (num) => {
        let value = parseFloat(num)
        let formatted = Math.round(value);
        return parseFloat(formatted).toLocaleString();
    }
    const Tformatnum = (number) => {
      if (isNaN(number) || number === null) {
        return "Invalid Number";
    }
    if (Number.isInteger(number)) {
        let formattedNumber = number.toString()
            formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return formattedNumber;
    } else {
        let formattedNumber = Number(number).toFixed(2);
        formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNumber;
    }
    }
    const getLocalISOTime = () => {
        const now = new Date();
        const offset = -now.getTimezoneOffset();
        const offsetHours = offset / 60;
        const offsetMinutes = Math.abs(offset % 60);
        const offsetSign = offset >= 0 ? '+' : '-';
        const pad = (num) => {
            const norm = Math.abs(Math.floor(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())}T${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}${offsetSign}${pad(offsetHours)}:${pad(offsetMinutes)}`;
    };
    const onSubmit = (values, { setSubmitting }) => {
        let ReqObj = {
            id: DetailsObj.id,
            fromDetailUserUploadedFileData: FileBase64Str,
            fromDetailUserUploadedTime: getLocalISOTime(),
            fromDetailUserUploadedByName: user,
            fromDetailUserUploadedFileName: FileBase64FileName
        }
        dispatch(UpdateContImprove(ReqObj));
        setReadyForDownload(true)
        setUpdateFile(false)
    };
    const UpdateFileHandel = () => {
        setUpdateFile(true)
        setFileValue(null)
    };
    const openInNewTab = () => {
        let dataUrl = ''
        dataUrl = `${FoorterData.fromDetailUserUploadedFileData}`;
        const newWindow = window.open();
        const htmlContent = `
            <!DOCTYPE html>
            <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>PDF Viewer</title>
                <style>
                    body { margin: 0; }
                    iframe { width: 100%; height: 100vh; border: none; }
                </style>
            </head>
            <body>
                <iframe src="${dataUrl}"></iframe>
            </body>
            </html>
        `;
        newWindow.document.open();
        newWindow.document.write(htmlContent);
        newWindow.document.close();
    }
    const DeleteCustomerFile = () => {
        let ReqObj = {
            id: DetailsObj.id,
            fromDetailUserUploadedFileData: '',
            fromDetailUserUploadedTime: getLocalISOTime(),
            fromDetailUserUploadedByName: '',
            fromDetailUserUploadedFileName: ''
        }
        const visitID = params.VisitID;
        dispatch(ConfirmAction("Do you want to delete this file?", UpdateContImprove(ReqObj), null,
            (confirmed) => {
                if (confirmed) {
                    setFileValue(null);
                    setFileBase64FileName('')
                    setFileBase64Str('');
                    setReadyForDownload(false);
                    // setUpdateFile(true)
                }
            }));
    };
    const UpdateFileCancel = () => {
        setUpdateFile(false)
        setFileValue(null)
    };
    const UpdateNewSelect = () => {
        setUpdateFile(true)
        setFileValue(null)
    };
    const handleFileInputChange = (event) => {
        const fileReader = new FileReader();
        fileReader.readAsText(event.target.files[0], "UTF-8");
        fileReader.onload = event => {
            setFileValue(event.target.result);
        };
        const file = event.target.files[0];
        setFileBase64FileName(file.name)
        convertFileToBase64(file);
    };
    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            const base64String = reader.result;
            setFileBase64Str(base64String);
        };
        reader.readAsDataURL(file);
    };
    const handleDownloadButtonClick = () => {
        // Create a temporary anchor element
        // const extension = FoorterData.fromDetailUserUploadedFileName.split('.').pop(); // Extract extension from file name
        const downloadLink = document.createElement('a');
        downloadLink.href = FoorterData.fromDetailUserUploadedFileData;
        downloadLink.download = FoorterData.fromDetailUserUploadedFileName; // Change the filename as needed
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };
    let imgStyle = {
        width: '100%',
        height: '80px'
    };

    if (!DetailsObj) {
        return <div>Loading Cointinuous Improvement Data from Database.</div>;
    }
    if (BaselineData !== null && wasteData !== null && DowntimeData !== null && ConsumptionData !== null && ProductionData !== null && TrainingData !== null && FoorterData !== null) {
        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3} sx={{ borderBottom: 2, paddingY: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                            <Grid item sx={{ display: 'flex', alignItems: 'center' }} align="center">
                                <img src={companyLogo} />

                            </Grid>
                            <Grid item sx={{ display: 'flex', alignItems: 'center' }} align="center">
                                <Typography color='primary' variant="h4" component="h4">
                                    Continuous Improvement Project
                                </Typography>

                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: '0px' }}>
                            <Grid item xs={9} sm={9} style={{ paddingTop: '5px' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<PrintIcon />}
                                    onClick={handlePrint}
                                >
                                    Print
                                </Button>
                            </Grid>
                            <Grid item xs={3} sm={3} style={{ paddingTop: '5px' }} align="right">
                                <Button style={{ marginInline: '5px' }}
                                    variant="contained"
                                    color="error"
                                    startIcon={<DeleteIcon />}
                                    onClick={handleDel}
                                >
                                    Delete
                                </Button>
                                <Button style={{ marginInline: '5px' }}
                                    variant="contained"
                                    startIcon={<EditIcon />}
                                    onClick={handleEdit}
                                >
                                    Update
                                </Button>
                                <Button style={{ marginInline: '5px' }}
                                    variant="contained"
                                    startIcon={<ArrowBackIcon />}
                                    onClick={handleClose}
                                >
                                    Go Back
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '12px' }}>
                            <Grid item md={6} lg={4} sm={4} align="left">
                                <div>
                                    <Typography variant="h5">Site: {PlantName}</Typography>
                                </div>
                            </Grid>
                            <Grid item md={6} lg={4} sm={4} align="left">
                                <div>
                                    {/* <Typography variant="h5">Machine / Operating Area: {PlantDObj !== null ? PlantDObj.region : ''}</Typography> */}
                                </div>
                            </Grid>
                            <Grid item md={6} lg={4} sm={4} align="left">
                                <div>
                                    <Typography variant="h5">By: {DetailsObj.signatureCaptureBy}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '12px' }}>
                            <Grid item align="left">
                                <Typography variant="h5">Project Summary</Typography>
                                <div>
                                    <ul style={{ marginLeft: '10px', margin: '0px' }}>

                                        {
                                            DetailsObj ?
                                                projectSummary.map((e, i) => (
                                                    <li key={i}>{e}</li>
                                                ))
                                                : <></>
                                        }
                                    </ul>
                                </div>
                            </Grid>

                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '12px' }}>
                            <Grid xs={12} item align="left">
                                <Typography variant="h5" style={{ fontWeight: 'bold' }}>Project Assumptions & Key Measurements</Typography>
                                <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                    <Table sx={{ ...commonStyles, width: '100%' }}>
                                        <TableHead>
                                            <TableRow sx={{ ...commonStyles }}>
                                                <TableCell sx={{ ...commonStyles }} colSpan={6}>
                                                    <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Plant Production Info Baseline 

                                                    { BaselineDObj.baselineStartDate !== '' ? ': (From ' + BaselineDObj.baselineStartDate + ' to ' + BaselineDObj.baselineEndDate + ')' : ''}
                                                    </Typography>
                                                    {/* <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Plant Production Info Baseline {BaselineDObj !== null ? BaselineDObj.noOfMonth : ''} {BaselineDObj.noOfMonth > 1 ? 'Months' : 'Month' }
                                                         </Typography> */}
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ ...commonStyles }}>
                                                    <Typography sx={{ ...cellText }}>AvgSpeed LFH</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.AvgSpeed)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Avg Width</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.AvgWidth)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>MSF/Hour</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.MSFPerHour)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ ...commonStyles }}>
                                                    <Typography sx={{ ...cellText }}>Days/Week</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.DaysPerWeek)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Actual %Waste</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{Tformatnum(BaselineData.ActualPrcWaste)}%</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Days/Year</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.DayPerYear)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ ...commonStyles }}>
                                                    <Typography sx={{ ...cellText }}>Shift/Day</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.ShiftPerDay)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Preprint %</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{Tformatnum(BaselineData.Preprint)}%</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Avg BG Cost</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.AvgBgCost)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ ...commonStyles }}>
                                                    <Typography sx={{ ...cellText }}>Hours/Shift</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.HoursPerShift)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Base Grade</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.BaseGrade)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Cost MSF w/PP</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{Tformatnum(BaselineData.CostMSFWPP)}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ ...commonStyles }}>
                                                    <Typography sx={{ ...cellText }}>Days/Month</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{Tformatnum(BaselineData.DayPerMonth)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Cost Rate</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.CostRate)}</Typography>
                                                </TableCell>
                                                {/* <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>Total Hours</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.TotalHours)}</Typography>
                                                </TableCell> */}
                                            </TableRow>
                                            {/* <TableRow>
                                                <TableCell sx={{ ...commonStyles }}>
                                                    <Typography sx={{ ...cellText }}>Sales Price ($/MSF)</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}>{formatnum(BaselineData.SalesPrice)}</Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}></Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}></Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}></Typography>
                                                </TableCell>
                                                <TableCell sx={{ ...commonStyles }} align="">
                                                    <Typography sx={{ ...cellText }}></Typography>
                                                </TableCell>
                                            </TableRow> */}
                                        </TableBody>
                                    </Table>
                                </div>
                            </Grid>
                            <Grid xs={12} item align="left">
                                <Typography variant="h5" style={{ fontWeight: 'bold' }}>Saving / Avoidance Calculation:</Typography>
                            </Grid>
                            {wasteData.IsWasteVisible ?
                                <Grid xs={12} xl={8} item align="left">
                                    <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                        <Table sx={{ ...commonStyles, width: '100%' }}>
                                            <TableHead>
                                                <TableRow sx={{ ...commonStyles }}>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={3}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Waste</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}></Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>Baseline</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>After Optimization</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Waste</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(BaselineDObj.actualPrcWaste)}%</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(wasteData.actualperWaste)}%</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={3}>
                                                        <Typography sx={{ ...cellText }}></Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>MSF Produced</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>{formatnum(wasteData.msfProduced)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Waste Reduction</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(wasteData.westReduction)}%</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Cost reduction/{wasteData.RadioValue === 'day' ? 'day' : wasteData.RadioValue === 'week' ? 'week' : 'month'}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>${formatnum(wasteData.costReduction)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={2}>
                                                        <Typography style={{ fontWeight: 'bold', display: 'flex', flex: 'flex-wrap' }} sx={{ ...cellText }}>Total Waste Saving for the Period:{
                                                            DetailsObj.periodValues > 0 ? ` ${formatDate(DetailsObj.startDate)} to ${formatDate(DetailsObj.enddate)} ( ${Tformatnum(DetailsObj.periodValues)}  ${' ' + DetailsObj.selectedRadioValue.charAt(0).toUpperCase() + DetailsObj.selectedRadioValue.slice(1) + `${ DetailsObj.periodValues > 1 ? 's' : ''}`}) ` : ''
                                                        }</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }}>${Tformatnum(wasteData.totalSavings)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {ProductionData.IsProductionVisible ?
                                <Grid xs={12} xl={8} item align="left">
                                    <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                        <Table sx={{ ...commonStyles, width: '100%' }}>
                                            <TableHead>
                                                <TableRow sx={{ ...commonStyles }}>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={3}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Production</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}></Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>Baseline</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>After Optimization</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Baseline LFPH</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(BaselineDObj.avgSpeed)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.newLPF)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Total Lineal</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.totalLineal)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.totalLinealNewProNum)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Total Sq.Ft</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.totalSqFt)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.totalSqFtNewProNum)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>MSF / Hour</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.msfPerHour)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.msfPerHourNewProNum)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>LBS / Hour</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.LbsPerHour)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.LbsPerNewProNum)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>$ / Hour</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>${Tformatnum(ProductionData.DolarPerHour)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>${Tformatnum(ProductionData.DolarPerHourNewProNum)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={3}>
                                                        <Typography sx={{ ...cellText }}></Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Increased Sales / HR</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>${Tformatnum(ProductionData.IncreaseSalsePerHr)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Increased MSF / HR</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.IncreaseMsfHR)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Msf Improved</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>{formatnum(ProductionData.MsfImproved)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Total Sales Increased</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>${formatnum(ProductionData.TotalSalesInc)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Cost Reduction Profit</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>${formatnum(ProductionData.CostReductProfit)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={2}>
                                                        <Typography style={{ fontWeight: 'bold', display: 'flex', flex: 'flex-wrap' }} sx={{ ...cellText }}>Total increased production for the Period:{
                                                            DetailsObj.periodValues > 0 ? ` ${formatDate(DetailsObj.startDate)} to ${formatDate(DetailsObj.enddate)} ( ${Tformatnum(DetailsObj.periodValues)}  ${' ' + DetailsObj.selectedRadioValue.charAt(0).toUpperCase() + DetailsObj.selectedRadioValue.slice(1) + `${ DetailsObj.periodValues > 1 ? 's' : ''}`}) ` : ''
                                                        }</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }}>${Tformatnum(ProductionData.TotalProctSaving)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {ConsumptionData.IsConsumptionVisible ?
                                <Grid xs={12} xl={8} item align="left">
                                    <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                        <Table sx={{ ...commonStyles, width: '100%' }}>
                                            <TableHead>
                                                <TableRow sx={{ ...commonStyles }}>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={3}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Consumption</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}></Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>Baseline</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>After Optimization</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Baseline</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(BaselineData.consumptionBaseline)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(DetailsObj.consLbPerMsf)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Starch Lb.Usage</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ConsumptionData.StarchIbUsage)}</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(ConsumptionData.NewStarchIbUsage)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={3}>
                                                        <Typography sx={{ ...cellText }}></Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Cost per Lb. per Starch</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>${Tformatnum(DetailsObj.consCostLb)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Starch Lb. Saving</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>{formatnum(ConsumptionData.StarchIbSaving)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Total Saving/day</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>${Tformatnum(ConsumptionData.TotalSaving)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }} colSpan={2}>
                                                        <Typography style={{ fontWeight: 'bold', display: 'flex', flex: 'flex-wrap' }} sx={{ ...cellText }}>Total Consumption Saving for the Period:{
                                                            DetailsObj.periodValues > 0 ? ` ${formatDate(DetailsObj.startDate)} to ${formatDate(DetailsObj.enddate)} ( ${Tformatnum(DetailsObj.periodValues)}  ${' ' + DetailsObj.selectedRadioValue.charAt(0).toUpperCase() + DetailsObj.selectedRadioValue.slice(1) + `${ DetailsObj.periodValues > 1 ? 's' : ''}`}) ` : ''
                                                        }</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }}>${Tformatnum(ConsumptionData.TotalConsumptionSaving)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {TrainingData.IsTrainingVisible ?
                                <Grid xs={12} xl={8} item align="left">
                                    <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                        <Table sx={{ ...commonStyles, width: '100%' }}>
                                            <TableHead>
                                                <TableRow sx={{ ...commonStyles }}>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Training</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Hours of Training</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(TrainingData.HourOfTraning)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>$/Hour</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>${formatnum(TrainingData.DollarHour)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold', display: 'flex', flex: 'flex-wrap' }} sx={{ ...cellText }}>Total Training Saving for the Period:{
                                                            DetailsObj.periodValues > 0 ? ` ${formatDate(DetailsObj.startDate)} to ${formatDate(DetailsObj.enddate)} ( ${Tformatnum(DetailsObj.periodValues)}  ${' ' + DetailsObj.selectedRadioValue.charAt(0).toUpperCase() + DetailsObj.selectedRadioValue.slice(1) + `${ DetailsObj.periodValues > 1 ? 's' : ''}`}) ` : ''
                                                        }</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold', minWidth: '145px' }} sx={{ ...cellText }}>${Tformatnum(TrainingData.TotalCostSaved)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {DowntimeData.IsShowDowntime ?
                                <Grid xs={12} xl={8} item align="left">
                                    <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                        <Table sx={{ ...commonStyles, width: '100%' }}>
                                            <TableHead>
                                                <TableRow sx={{ ...commonStyles }}>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Downtime</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Hours DT Saved</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{Tformatnum(DowntimeData.HoursDTSaved)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Total Lineal</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(DowntimeData.TotalLineal)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>Total Sq. Ft</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(DowntimeData.TotalSqFt)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>MSF / Day</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(DowntimeData.MsgDay)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography sx={{ ...cellText }}>LBS / Day</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="">
                                                        <Typography sx={{ ...cellText }}>{formatnum(DowntimeData.LbsDay)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold', display: 'flex', flex: 'flex-wrap' }} sx={{ ...cellText }}> Total Downtime DT/Production Saving for the Period:{
                                                            DetailsObj.periodValues > 0 ? ` ${formatDate(DetailsObj.startDate)} to ${formatDate(DetailsObj.enddate)} ( ${Tformatnum(DetailsObj.periodValues)}  ${' ' + DetailsObj.selectedRadioValue.charAt(0).toUpperCase() + DetailsObj.selectedRadioValue.slice(1) + `${ DetailsObj.periodValues > 1 ? 's' : ''}`}) ` : ''
                                                        }</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold', minWidth: '145px' }} sx={{ ...cellText }}>${Tformatnum(DowntimeData.DollarPerSaved)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                                : <></>
                            }
                            {FoorterData !== null ?
                                <Grid xs={12} xl={8} item align="left">
                                    <div style={{ marginRight: '20px', marginLeft: '10px' }}>
                                        <Table sx={{ ...commonStyles, width: '100%' }}>
                                            <TableHead>
                                                <TableRow sx={{ ...commonStyles }}>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold' }} sx={{ ...cellText }} variant="h6">Total Savings</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { wasteData.IsWasteVisible ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Waste</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Waste)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                { ProductionData.IsProductionVisible ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Production</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Production)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {ConsumptionData.IsConsumptionVisible ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Consumption</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Consumption)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {TrainingData.IsTrainingVisible ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Training</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Training)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {DowntimeData.IsShowDowntime ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Downtime</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Downtime)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {/* {parseInt(FoorterData.Waste) > 0 ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Waste</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Waste)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {parseInt(FoorterData.Production) > 0 ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Production</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Production)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {parseInt(FoorterData.Consumption) > 0 ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Consumption</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Consumption)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {parseInt(FoorterData.Training) > 0 ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Training</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Training)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                }
                                                {parseInt(FoorterData.Downtime) > 0 ?
                                                    <TableRow>
                                                        <TableCell sx={{ ...commonStyles }}>
                                                            <Typography sx={{ ...cellText }}>Downtime</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ ...commonStyles }} align="right">
                                                            <Typography sx={{ ...cellText }}>${Tformatnum(FoorterData.Downtime)}</Typography>
                                                        </TableCell>
                                                    </TableRow> : <></>
                                                } */}
                                                <TableRow>
                                                    <TableCell sx={{ ...commonStyles }}>
                                                        <Typography style={{ fontWeight: 'bold', display: 'flex', flex: 'flex-wrap' }} sx={{ ...cellText }}> Grand Total Saving for the Period:{
                                                            DetailsObj.periodValues > 0 ? ` ${formatDate(DetailsObj.startDate)} to ${formatDate(DetailsObj.enddate)} ( ${Tformatnum(DetailsObj.periodValues)}  ${' ' + DetailsObj.selectedRadioValue.charAt(0).toUpperCase() + DetailsObj.selectedRadioValue.slice(1) + `${ DetailsObj.periodValues > 1 ? 's' : ''}`}) ` : ''
                                                        }</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonStyles }} align="right">
                                                        <Typography style={{ fontWeight: 'bold', minWidth: '145px' }} sx={{ ...cellText }}>${Tformatnum(FoorterData.GrandTotal)}</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid>
                                : <></>
                            }



                        </Grid>
                        <Grid container spacing={3} style={{ marginTop: '50px', marginBottom: '50px' }} sx={{ borderTop: 2 }}>
                        {FoorterData.CiAddedbySig !== "" ?
                            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} align="center">
                                {/* <img src={FoorterData.CiAddedbySig} /> */}

                                <div>
                                    <div style={{ border: '1px solid black', padding: 20 }}>
                                        <img src={"data:" + 'image/png' + ";base64," + FoorterData.CiAddedbySig} style={imgStyle} />
                                    </div>
                                    <div>
                                        {moment(FoorterData.CiAddedbySigTime).format('MM/DD/YY hh:mm a')}
                                    </div>
                                    <div>
                                        {FoorterData.CiAddedbyName}
                                    </div>
                                    <div>
                                        {FoorterData.PositionName}
                                    </div>
                                    <div>
                                        Corrugated Chemicals Inc.
                                    </div>
                                </div>

                            </Grid>
                                : <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} align="center"></Grid>
                            }
                            {FoorterData.GmSignature !== "" ?
                                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} align="center">

                                    <div>
                                        <div style={{ border: '1px solid black', padding: 20 }}>
                                            <img src={"data:" + 'image/png' + ";base64," + FoorterData.GmSignature} style={imgStyle} />
                                        </div>
                                        <div>
                                            {moment(FoorterData.GmSignatureTime).format('MM/DD/YY hh:mm a')}
                                        </div>
                                        <div>
                                            {
                                                !DetailsObj.isSignedByGM 
                                                ? `${PlantDObj.name} Representative / Date`
                                                : `${PlantDObj.name} GM / Date`
                                            }
                                        </div>
                                        <div>
                                            {DetailsObj.gmRepDisplayUserName}
                                        </div>
                                        <div>
                                            {PlantName}
                                        </div>
                                    </div>
                                </Grid>
                                : <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} align="center"></Grid>
                            }
                            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} align="center">
                                <div>
                                    <div>
                                        {
                                            ReadyForDownload && !UpdateFile ? <>

                                                <div>
                                                    Uploaded file:
                                                </div>
                                                <div>
                                                    {FoorterData.fromDetailUserUploadedFileName} On {moment(FoorterData.fromDetailUserUploadedTime).format('MM/DD/YY hh:mm a')}
                                                    {/* <span>
                                                        <IconButton color='primary'
                                                            size="small">
                                                            <PictureAsPdfIcon />
                                                        </IconButton>
                                                    </span> */}
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                                                    <Typography color={'primary'}><span onClick={openInNewTab} style={{ cursor: 'pointer', textAlign: 'center' }}>Open 
                                                        <IconButton color='primary'
                                                            size="small">
                                                            <LaunchIcon />
                                                        </IconButton></span></Typography>
                                                    {/* <Typography color={'primary'}><span onClick={UpdateFileHandel} style={{ cursor: 'pointer' }}>Update</span></Typography> */}
                                                    {/* <Typography color={'primary'}><span onClick={handleDownloadButtonClick} style={{ cursor: 'pointer' }}>Download</span></Typography> */}
                                                    <Typography color={'primary'}><span onClick={handleDownloadButtonClick} style={{ cursor: 'pointer', textAlign: 'center' }}>Download
                                                        <IconButton color='primary'
                                                            size="small">
                                                            <DownloadIcon />
                                                        </IconButton></span></Typography>
                                                    <Typography color={'error'}><span onClick={DeleteCustomerFile} style={{ cursor: 'pointer', textAlign: 'center' }}>Delete
                                                        <IconButton color='error'
                                                            size="small">
                                                            <DeleteIcon />
                                                        </IconButton></span></Typography>
                                                </div>
                                            </> :
                                                <Formik
                                                    initialValues={initialValues}
                                                    onSubmit={onSubmit}
                                                >
                                                    {({ isSubmitting, setFieldValue }) => (
                                                        <Form>
                                                            <div style={{ textAlign: 'left' }}>
                                                                Uploaded file recived by customer
                                                            </div>
                                                            <input type="file" accept=".pdf"  onChange={handleFileInputChange}
                                                            />
                                                            <div>
                                                            <Button type="submit" disabled={FileValue === null}>
                                                                Submit
                                                            </Button>
                                                            </div>

                                                            {
                                                                FoorterData.fromDetailUserUploadedFileName !== '' && FoorterData.fromDetailUserUploadedFileName !== null && FileBase64Str !== '' ? <Button type="submit" onClick={UpdateFileCancel}> Cancel </Button> : <></>
                                                            }

                                                        </Form>
                                                    )}
                                                </Formik>
                                        }
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </>
        );
    } else {
        return <div>Loading Cointinuous Improvement Data from Database.</div>;
    }
}
export default ContinuousImprovementView;




function GetTrainingData(DetailsObj) {
    let TrainObj = {
        IsTrainingVisible: GetCheckIsSelected('hoursOfTraining', DetailsObj),
        HourOfTraning: 0,
        DollarHour: 250,
        TotalCostSaved: 0
    }
    if (DetailsObj.hoursOfTraining > 0) {
        TrainObj.HourOfTraning = DetailsObj.hoursOfTraining
        TrainObj.TotalCostSaved = TrainObj.DollarHour * TrainObj.HourOfTraning
    }
    return TrainObj
}
function GetDownShiftData(DetailsObj, selectedBaseline) {
    let TrainObj = {
        IsShowDowntime: GetCheckIsSelected('hoursOfDownTime', DetailsObj),
        HoursDTSaved: 0,
        TotalLineal: 0,
        TotalSqFt: 0,
        MsgDay: 0,
        LbsDay: 0,
        DollarPerSaved: 0
    }
    if (DetailsObj.hoursOfDownTime > 0) {
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        TrainObj.HoursDTSaved = DetailsObj.hoursOfDownTime
        TrainObj.TotalLineal = DetailsObj.hoursOfDownTime * selectedBaseline.avgSpeed
        TrainObj.TotalSqFt = TrainObj.TotalLineal * (selectedBaseline.avgWidth / 12)
        TrainObj.MsgDay = TrainObj.TotalSqFt / 1000
        TrainObj.LbsDay = (TrainObj.TotalSqFt / 1000) * selectedBaseline.baseGrade
        TrainObj.DollarPerSaved = ((TrainObj.TotalSqFt / 1000) * costMSFWperPP)
        console.log('TrainObj', TrainObj)
    }
    return TrainObj
}
function GetFooterData(DetailsObj, wasteData, TrainingData, DowntimeData, ConsumptionData, ProductionData, plantDet) {
    console.log('111111111111111111111111111111wasteData', wasteData)
    console.log('111111111111111111111111111111TrainingData', TrainingData)
    console.log('111111111111111111111111111111DowntimeData', DowntimeData)
    console.log('111111111111111111111111111111ConsumptionData', ConsumptionData)
    console.log('111111111111111111111111111111ProductionData', ProductionData)
    let Object = {
        TotalSavings: 0,
        PositionName: '',
        Waste: 0,
        Production: 0,
        Consumption: 0,
        Downtime: 0,
        Training: 0,
        GrandTotal: 0,
        SupplierName: '',
        DisplayDate: '',
        CiAddedbyName: DetailsObj.signatureCaptureBy,
        CiAddedbySig: DetailsObj.signatureCapture,
        CiAddedbySigTime: DetailsObj.userSignatureCaptureTime,
        GmSignature: DetailsObj.gmSignatureData,
        GmSignatureTime: DetailsObj.gmSignatureCaptureTime,
        GmSignatureAddedBy: DetailsObj.GmSignatureCaptureBy,
        fromDetailUserUploadedFileData: DetailsObj.fromDetailUserUploadedFileData,
        fromDetailUserUploadedFileName: DetailsObj.fromDetailUserUploadedFileName,
        fromDetailUserUploadedTime: DetailsObj.fromDetailUserUploadedTime,
        fromDetailUserUploadedByName: DetailsObj.fromDetailUserUploadedByName !== '' && DetailsObj.fromDetailUserUploadedByName !== null ? DetailsObj.fromDetailUserUploadedByName : ''
    }
    let start = new Date(DetailsObj.startDate);
    let end = new Date(DetailsObj.enddate);
    let startMonth = start.toLocaleString('default', { month: 'long' });
    let endMonth = end.toLocaleString('default', { month: 'long' });
    Object.DisplayDate = `${startMonth} ${start.getDate()}-${end.getDate()}, ${start.getFullYear()}`;
    Object.Waste = (DetailsObj.totalWasteSavings)
    Object.Training = (TrainingData.TotalCostSaved)
    Object.Downtime = (DowntimeData.DollarPerSaved)
    Object.Production = ProductionData.TotalProctSaving
    Object.Consumption = (ConsumptionData.TotalConsumptionSaving)

    // Object.GrandTotal = (DetailsObj.totalWasteSavings + DetailsObj.totalProductionSaving + DetailsObj.totalConsumptionSavings + DetailsObj.totalTrainingCostSaved + DetailsObj.totalDownTimeSaved)
    let total = 0
    if (wasteData.IsWasteVisible) {
        total += DetailsObj.totalWasteSavings
    }
    if (ProductionData.IsProductionVisible) {
        total += DetailsObj.totalProductionSaving
    }
    if (ConsumptionData.IsConsumptionVisible) {
        total += DetailsObj.totalConsumptionSavings
    }
    if (TrainingData.IsTrainingVisible) {
        total += DetailsObj.totalTrainingCostSaved
    }
    if (DowntimeData.IsShowDowntime) {
        total += DetailsObj.totalDownTimeSaved
    }
    Object.GrandTotal = total

    if (DetailsObj.createdByID !== "00000000-0000-0000-0000-000000000000") {
        if (plantDet.usrareamanagerId === DetailsObj.createdByID) {
            Object.PositionName = 'Regional Manager'
        } else if (plantDet.usrprimarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Service Lead'
        } else if (plantDet.usrsecondarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Secondary Tech'
        } else if (plantDet.usrtertiarytechId === DetailsObj.createdByID) {
            Object.PositionName = 'Tertiary Tech'
        } else if (plantDet.ownerId === DetailsObj.createdByID) {
            Object.PositionName = 'Owner'
        }
    } else {
        if (plantDet.usrareamanagerId === DetailsObj.updatedByID) {
            Object.PositionName = 'Regional Manager'
        } else if (plantDet.usrprimarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Service Lead'
        } else if (plantDet.usrsecondarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Secondary Tech'
        } else if (plantDet.usrtertiarytechId === DetailsObj.updatedByID) {
            Object.PositionName = 'Tertiary Tech'
        } else if (plantDet.ownerId === DetailsObj.updatedByID) {
            Object.PositionName = 'Owner'
        }
    }






    return Object
}
function GetBaseLineData(DetailsObj, selectedBaseline) {
    console.log('BaselineBaselineBaseline------>', selectedBaseline)
    let baselineData = {
        ID: selectedBaseline.id,
        PlantID: selectedBaseline.plantID,
        BaselineName: selectedBaseline.baselineName,
        AvgSpeed: selectedBaseline.avgSpeed,
        DaysPerWeek: selectedBaseline.daysPerWeek,
        ShiftPerDay: selectedBaseline.shiftPerDay,
        HoursPerShift: selectedBaseline.hoursPerShift,
        NoOfMonth: selectedBaseline.noOfMonth,
        AvgWidth: selectedBaseline.avgWidth,
        ActualPrcWaste: selectedBaseline.actualPrcWaste,
        Preprint: selectedBaseline.preprint,
        BaseGrade: selectedBaseline.baseGrade,
        CostRate: selectedBaseline.costRate,
        DayPerMonth: '',
        MSFPerHour: '',
        DayPerYear: '',
        AvgBgCost: '',
        CostMSFWPP: '',
        TotalHours: '',
        CountMargin: '',
        SalesPrice: 120,
        consumptionBaseline: selectedBaseline.consumptionBaseline,
        Deleted: selectedBaseline.deleted,
        CreatedDate: selectedBaseline.createdDate,
        LastSyncDate: selectedBaseline.lastSyncDate,
        CreatedByID: selectedBaseline.createdByID,
        CreatedBy: selectedBaseline.createdBy,
        UpdatedByID: selectedBaseline.updatedByID,
        UpdatedBy: selectedBaseline.updatedBy,
    }
    let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
    baselineData.DayPerMonth = selectedBaseline.daysPerWeek * 4.25
    baselineData.MSFPerHour = selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000
    baselineData.DayPerYear = (selectedBaseline.daysPerWeek * 4.25) * 12
    baselineData.AvgBgCost = avgBgCost
    baselineData.CostMSFWPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
    baselineData.TotalHours = selectedBaseline.avgSpeed * selectedBaseline.daysPerWeek * selectedBaseline.shiftPerDay
    baselineData.CountMargin = 120 - baselineData.CostMSFWPP
    return baselineData;
}

function GetCheckIsSelected(from, DetailsObj) {
    if (from === 'waste') {
        if (DetailsObj.newPerWaste > 0 ) {
            return true
        } else {
            return false
        }
    } else if (from === 'production') {
        if (DetailsObj.proNewLinealPerHr > 0) {
            return true
        } else {
            return false
        }
    } else if (from === 'consumption') {
        if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0) {
            return true
        } else {
            return false
        }
    } else if (from === 'hoursOfTraining') {
        if (DetailsObj.hoursOfTraining > 0) {
            return true
        } else {
            return false
        }
    } else if (from === 'hoursOfDownTime') {
        if (DetailsObj.hoursOfDownTime > 0) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }

}
// function GetCheckIsSelected(from, DetailsObj) {
//     if (from === 'waste') {
//         if (DetailsObj.newPerWaste > 0 && DetailsObj.wasteTotalCount > 0) {
//             return true
//         } else {
//             return false
//         }
//     } else if (from === 'production') {
//         if (DetailsObj.proNewLinealPerHr > 0 && DetailsObj.proTotalCount > 0) {
//             return true
//         } else {
//             return false
//         }
//     } else if (from === 'consumption') {
//         if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0 && DetailsObj.consTotalCount > 0) {
//             return true
//         } else {
//             return false
//         }
//     } else if (from === 'hoursOfTraining') {
//         if (DetailsObj.hoursOfTraining > 0) {
//             return true
//         } else {
//             return false
//         }
//     } else if (from === 'hoursOfDownTime') {
//         if (DetailsObj.hoursOfDownTime > 0) {
//             return true
//         } else {
//             return false
//         }
//     } else {
//         return false
//     }

// }
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function GetWasteObj(DetailsObj, selectedBaseline) {
    console.log('WASTE123--->', DetailsObj)
    let WasteObj = {
        IsWasteVisible: GetCheckIsSelected('waste', DetailsObj),
        RadioValue: DetailsObj.selectedRadioValue,
        msfProduced: 0,
        actualperWaste: 0,
        westReduction: 0,
        costReduction: 0,
        totalSavings: 0,
    }
    if (DetailsObj.newPerWaste > 0 && DetailsObj.wasteTotalCount > 0) {
        let msfProduced = selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)
        let actualperWaste = selectedBaseline.actualPrcWaste
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        let westReduction = actualperWaste - DetailsObj.newPerWaste
        if (DetailsObj.selectedRadioValue === 'day') {
            let costReduction = westReduction / 100 * msfProduced * costMSFWperPP
            let totalSavings = costReduction * DetailsObj.wasteTotalCount
            WasteObj.msfProduced = msfProduced
            WasteObj.actualperWaste = DetailsObj.newPerWaste
            WasteObj.westReduction = westReduction
            WasteObj.costReduction = costReduction
            WasteObj.totalSavings = totalSavings
        } else if (DetailsObj.selectedRadioValue === 'week') {
            let msfProducedWeek = msfProduced * selectedBaseline.daysPerWeek
            let actualperWasteWeek = DetailsObj.newPerWaste
            let westReductionWeek = actualperWaste - actualperWasteWeek
            let costReductionWeek = westReductionWeek / 100 * msfProducedWeek * costMSFWperPP
            let totalSavingsWeek = costReductionWeek * DetailsObj.wasteTotalCount
            WasteObj.msfProduced = msfProducedWeek
            WasteObj.actualperWaste = actualperWasteWeek
            WasteObj.westReduction = westReductionWeek
            WasteObj.costReduction = costReductionWeek
            WasteObj.totalSavings = totalSavingsWeek
        } else if (DetailsObj.selectedRadioValue === 'month') {
            let msfProducedMonth = ((selectedBaseline.daysPerWeek * 4.25) * msfProduced)
            let actualperWasteMonth = DetailsObj.newPerWaste
            let westReductionMonth = actualperWaste - actualperWasteMonth
            let costReductionMonth = westReductionMonth / 100 * msfProducedMonth * costMSFWperPP
            let totalSavingsMonth = costReductionMonth * DetailsObj.wasteTotalCount
            WasteObj.msfProduced = msfProducedMonth
            WasteObj.actualperWaste = actualperWasteMonth
            WasteObj.westReduction = westReductionMonth
            WasteObj.costReduction = costReductionMonth
            WasteObj.totalSavings = totalSavingsMonth
        }
    }
    return WasteObj;
}
function GetProductionObj(DetailsObj, selectedBaseline) {
    let ProObj = {
        IsProductionVisible: GetCheckIsSelected('production', DetailsObj),
        RadioValue: DetailsObj.selectedRadioValue,
        newLPF: 0,
        totalLineal: 0,
        totalLinealNewProNum: 0,
        totalSqFt: 0,
        totalSqFtNewProNum: 0,
        msfPerHour: 0,
        msfPerHourNewProNum: 0,
        LbsPerHour: 0,
        LbsPerNewProNum: 0,
        DolarPerHour: 0,
        DolarPerHourNewProNum: 0,
        MsfImproved: 0,
        TotalSalesInc: 0,
        CostReductProfit: 0,
        TotalProctSaving: 0,
        IncreaseMsfHR: 0,
        IncreaseSalsePerHr: 0
    }
    if (DetailsObj.proNewLinealPerHr > 0 && DetailsObj.proTotalCount > 0) {
        let avgBgCost = constants.BLine.BasisWeight[selectedBaseline.baseGrade]
        let costMSFWperPP = ((selectedBaseline.preprint / 100 * (avgBgCost * 2.5)) + (((1 - selectedBaseline.preprint / 100) * avgBgCost) * selectedBaseline.costRate))
        let totalLineal = selectedBaseline.avgSpeed * 24
        let totalLinealNewProNum = DetailsObj.proNewLinealPerHr * 24
        let totalSqFt = totalLineal * (selectedBaseline.avgWidth / 12)
        let totalSqFtNewProNum = totalLinealNewProNum * (selectedBaseline.avgWidth / 12)
        let msfPerHour = totalSqFt / 24 / 1000
        let msfPerHourNewProNum = totalSqFtNewProNum / 24 / 1000
        let LbsPerHour = msfPerHour * selectedBaseline.baseGrade
        let LbsPerNewProNum = msfPerHourNewProNum * selectedBaseline.baseGrade
        let DolarPerHour = msfPerHour * costMSFWperPP
        let DolarPerHourNewProNum = msfPerHourNewProNum * costMSFWperPP

        ProObj.newLPF = DetailsObj.proNewLinealPerHr

        ProObj.totalLineal = totalLineal
        ProObj.totalLinealNewProNum = totalLinealNewProNum

        ProObj.totalSqFt = totalSqFt
        ProObj.totalSqFtNewProNum = totalSqFtNewProNum

        ProObj.msfPerHour = msfPerHour
        ProObj.msfPerHourNewProNum = msfPerHourNewProNum

        ProObj.LbsPerHour = LbsPerHour
        ProObj.LbsPerNewProNum = LbsPerNewProNum

        ProObj.DolarPerHour = DolarPerHour
        ProObj.DolarPerHourNewProNum = DolarPerHourNewProNum

        ProObj.IncreaseMsfHR = msfPerHourNewProNum - msfPerHour
        ProObj.IncreaseSalsePerHr = DolarPerHourNewProNum - DolarPerHour

        let MsfImproved = (msfPerHourNewProNum - msfPerHour) * (selectedBaseline.hoursPerShift * selectedBaseline.shiftPerDay)
        if (DetailsObj.selectedRadioValue === 'day') {
            // console.log('day');

            let TotalSalesInc = MsfImproved * costMSFWperPP
            let CostReductProfit = TotalSalesInc * 0.3
            let TotalProctSaving = DetailsObj.proTotalCount * CostReductProfit

            ProObj.MsfImproved = MsfImproved
            ProObj.TotalSalesInc = TotalSalesInc
            ProObj.CostReductProfit = CostReductProfit
            ProObj.TotalProctSaving = TotalProctSaving

        } else if (DetailsObj.selectedRadioValue === 'week') {
            // console.log('weeek');

            let MsfImprovedWeek = MsfImproved * selectedBaseline.daysPerWeek
            let TotalSalesInc = MsfImprovedWeek * costMSFWperPP
            let CostReductProfit = TotalSalesInc * 0.3
            let TotalProctSaving = DetailsObj.proTotalCount * CostReductProfit

            ProObj.MsfImproved = MsfImprovedWeek
            ProObj.TotalSalesInc = TotalSalesInc
            ProObj.CostReductProfit = CostReductProfit
            ProObj.TotalProctSaving = TotalProctSaving
        } else if (DetailsObj.selectedRadioValue === 'month') {
            // console.log('month');

            let MsfImprovedWeek = MsfImproved * selectedBaseline.daysPerWeek * 4.25
            let TotalSalesInc = MsfImprovedWeek * costMSFWperPP
            let CostReductProfit = TotalSalesInc * 0.3
            let TotalProctSaving = DetailsObj.proTotalCount * CostReductProfit

            ProObj.MsfImproved = MsfImprovedWeek
            ProObj.TotalSalesInc = TotalSalesInc
            ProObj.CostReductProfit = CostReductProfit
            ProObj.TotalProctSaving = TotalProctSaving
        }
    }
    return ProObj
}
function GetConsumptionObj(DetailsObj, selectedBaseline) {
    console.log('cusomption -----------DetailsObj-----', DetailsObj);
    console.log('cusomption --------------selectedBaseline--', selectedBaseline);
    let CompObj = {
        IsConsumptionVisible: GetCheckIsSelected('consumption', DetailsObj),
        RadioValue: DetailsObj.selectedRadioValue,
        StarchIbUsage: 0,
        NewStarchIbUsage: 0,
        StarchIbSaving: 0,
        TotalSaving: 0,
        TotalConsumptionSaving: 0
    }
    if (DetailsObj.consLbPerMsf > 0 && DetailsObj.consCostLb > 0 && DetailsObj.consTotalCount > 0) {
        let StarchIbUsage = selectedBaseline.consumptionBaseline * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)
        let NewStarchIbUsage = (DetailsObj.consLbPerMsf * (selectedBaseline.avgSpeed * selectedBaseline.avgWidth / 12 / 1000)) * (selectedBaseline.shiftPerDay * selectedBaseline.hoursPerShift)

        let msfProducedMonth = selectedBaseline.daysPerWeek * 4.25
        let msfProducedWeek = selectedBaseline.daysPerWeek

        let TotalConsumptionSaving = 0
        if (DetailsObj.selectedRadioValue === 'day') {
            let StarchIbSaving = StarchIbUsage - NewStarchIbUsage
            let TotalSaving = DetailsObj.consCostLb * StarchIbSaving
            TotalConsumptionSaving = TotalSaving * DetailsObj.consTotalCount

            CompObj.StarchIbUsage = StarchIbUsage
            CompObj.NewStarchIbUsage = NewStarchIbUsage
            CompObj.StarchIbSaving = StarchIbSaving
            CompObj.TotalSaving = TotalSaving
            CompObj.TotalConsumptionSaving = TotalConsumptionSaving
        } else if (DetailsObj.selectedRadioValue === 'week') {
            // console.log('weeek');
            let StarchIbUsageM = StarchIbUsage * msfProducedWeek
            let NewStarchIbUsageM = NewStarchIbUsage * msfProducedWeek
            let StarchIbSaving = StarchIbUsageM - NewStarchIbUsageM
            let TotalSaving = DetailsObj.consCostLb * StarchIbSaving
            TotalConsumptionSaving = TotalSaving * DetailsObj.consTotalCount

            CompObj.StarchIbUsage = StarchIbUsageM
            CompObj.NewStarchIbUsage = NewStarchIbUsageM
            CompObj.StarchIbSaving = StarchIbSaving
            CompObj.TotalSaving = TotalSaving
            CompObj.TotalConsumptionSaving = TotalConsumptionSaving
        } else if (DetailsObj.selectedRadioValue === 'month') {
            let StarchIbUsageM = StarchIbUsage * msfProducedMonth
            let NewStarchIbUsageM = NewStarchIbUsage * msfProducedMonth
            let StarchIbSaving = StarchIbUsageM - NewStarchIbUsageM
            let TotalSaving = DetailsObj.consCostLb * StarchIbSaving
            TotalConsumptionSaving = TotalSaving * DetailsObj.consTotalCount

            CompObj.StarchIbUsage = StarchIbUsageM
            CompObj.NewStarchIbUsage = NewStarchIbUsageM
            CompObj.StarchIbSaving = StarchIbSaving
            CompObj.TotalSaving = TotalSaving
            CompObj.TotalConsumptionSaving = TotalConsumptionSaving
        }


    }
    return CompObj
}