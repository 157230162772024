import Constants from '../constants';
import produce from "immer";
import { UpdateArray,UpdateArrayRange } from '../misc/ArrayFuncs';
const initDataState = [];
//TODO: Normalize Data Maybe?
const StartchGraphRecord = (state = initDataState, action) => {
    switch (action.type) {
        case Constants.Actions.StartchGrpRecords:
            return UpdateArrayRange(action.data, state);
        default:
            return state;
    }
}
export default StartchGraphRecord;