import {  combineReducers } from 'redux';
import Data from './Data';
import Surveys from './SurveysReducer';
import Attachments from './AttachmentStore';
import ActionItems from './ActionItemStore';
import ActionItemLinks from './ActionItemLinkStore';
import MetaData from './MetadataStore';
import IngredientVerifications from './IngredientVerificationStore';
import IngredientVerificationSteps from './IngredientVerificationStepStore';
import Plants from './PlantsStore';
import Personnel from './PersonnelStore';
import CorrugatorAdhesiveData from './CorrugatorAdhesiveDataStore';
import DesiredSpeedImprovements from './DesiredSpeedImprovementsStore';
import Lines from './LinesStore';
import Machines from './MachinesStore';
import MachineFlutes from './MachineFlutesStore';
import MachineVessels from './MachineVesselsStore';
import Formulas from './FormulasStore';
import FormulaSteps from './FormulaStepsStore';
import FormulaChanges from './FormulaChangesStore';
import Notifications from './NotificationsStore';
import Visits from './VisitsStore';
import SyncErrors from './SyncErrorsStore';
import Prompt from './PromptStore';
import KeyItems from './KeyItemsStore'
import ConfirmAction from './ConfirmActionStore';
import AlertAction from './AlertActionStore';
import Auth from './AuthStore';
import HeatVessels from './HeatVesselStore';
import HeatVesselTemps from './HeatVesselTempsStore';
import GlueRolls from './GlueRollStore';
import GlueRollMeasurements from './GlueRollMeasurementsStore';
import GlueRollPictures from './GlueRollPicturesStore';
import GlueLines from './GlueLineStore';
import GlueLineMachines from './GlueLineMachinesStore';
import RequestStatus from './RequestStatusStore';
import StarchKitchenItems from './StarchKitchenItemsStore';
import Inventory from './InventoryStore';
import StarchMeasurement from './StarchMeasurementStore';
import StartchGraphRecord from './StartchGraphRecordStore';
import Menu from './MenuStore';
import Checklist from './ChecklistStore';
import ChecklistGroup from './ChecklistGroupStore';
import ChecklistItem from './ChecklistItemStore';
import NetworkState from './NetworkStateStore';
import LastSync from './LastSyncStore';
import Reports from './ReportsStore';
import OutboundEmail from './OutboundEmailStore';
import Thermals from './ThermalStore';
import ThermalImages from './ThermalImagesStore';
import Baselines from './BaselinesStore';
import ContImprove from './ContinuousImprovementStore'
export const CreateRootReducer = (history) => combineReducers({
    Data,
    Surveys,
    Attachments,
    ActionItems,
    ActionItemLinks,
    MetaData,
    IngredientVerifications,
    IngredientVerificationSteps,
    Plants,
    Personnel,
    CorrugatorAdhesiveData,
    DesiredSpeedImprovements,
    Lines,
    Machines,
    MachineFlutes,
    MachineVessels,
    Formulas,
    FormulaSteps,
    FormulaChanges,
    Notifications,
    SyncErrors,
    Prompt,
    Visits,
    KeyItems,
    Baselines,
    ContImprove,
    ConfirmAction,
    AlertAction,
    Auth,
    HeatVessels,
    HeatVesselTemps,
    RequestStatus,
    GlueRolls,
    GlueRollMeasurements,
    GlueRollPictures,
    GlueLines,
    GlueLineMachines,
    StarchKitchenItems,
    Inventory,
    StarchMeasurement,
    StartchGraphRecord,
    Menu,
    Checklist,
    ChecklistGroup,
    ChecklistItem,
    NetworkState,
    LastSync,
    Reports,
    OutboundEmail,
    Thermals,
    ThermalImages
});