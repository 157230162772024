import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
export const PlantLink = props => {
    const PlantData = useSelector(state => state.Plants.find(p => p.id == props.plantID));
    if (PlantData) {
        return <Link to={"/PlantEdit/" + PlantData.id + '/' + 0}>{PlantData.name}</Link>;
    } else {
        return <p>Not Plant Selected</p>;
    }
}