//Modules
import React, { Component } from 'react';
import { useSelector,shallowEqual } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createSelector } from 'reselect';
//MUI
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import MUIDataTable from "mui-datatables";
//Components
import AddCustomToolbar from "./AddCustomToolbar";
import CustomSelectToolbar from './CustomSelectToolbar';
import { PlantsFilteredByContact } from '../selectors/PlantSelectors';

//Selectors

const PlantClickTracking =[];
const PlantTable =props => {
    const contactId=useSelector(s=>s.Auth.userData?.contactID,shallowEqual);
    const Plants = useSelector(s=>PlantsFilteredByContact(s,contactId).sort((a,b)=>{
        //b.clickCount -a.clickCount 
        const bClick=PlantClickTracking.find(c=>c.plantID==b.id)??{count:0};
        const aClick=PlantClickTracking.find(c=>c.plantID==a.id)??{count:0};
        return bClick.count-aClick.count;
    }));
    const nagivate = useNavigate();
    const rowSelect=(rowsSelectedData, allRows, rowsSelected)=> {
        console.log(rowsSelectedData, allRows, rowsSelected);
        if (rowsSelected.length > 0) {
            const selPlant=Plants[rowsSelected[0]];
            const clickCount=PlantClickTracking.find(c=>c.plantID==selPlant.id);
            if(clickCount){
                clickCount.count++;
            } else {
                PlantClickTracking.push({plantID:selPlant.id,count:1});
            }
            props.OnChange(selPlant);
        } else {
            props.OnChange(null);
        }
    }
    const GotoPlant=()=> {
        //Production
        if (Object.keys(props.SelectedPlant).length > 0) {
            nagivate('/PlantEdit/' + props.SelectedPlant.id + "/" + 0);
        } 
    }
    const getSelectedRows=()=> {
        if (props.SelectedPlant) {
            const ind = Plants.findIndex(p => p.id === props.SelectedPlant.id);
            if (ind >= 0) {
                return [ind];
            }
        }
        return [];
    }
   
    const columns = [{
        name: "name",
        label: "Name",
        options: {
            filter: true,
            sort: true,
            filterType: 'textField'
        }
    },
    {
        name: "region",
        label: "Region"
    },];

    const options = {
        filterType: 'checkbox', selectableRows: 'single', selectableRowsOnClick: true, print: false,
        download: false,
        fixedHeader: true,
        responsive: 'standard',
        customToolbar: () => {
            return null;
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return <CustomSelectToolbar selectedRows={selectedRows} displayData={displayData} setSelectedRows={setSelectedRows} clickEdit={GotoPlant} />;
        },
        onRowSelectionChange: rowSelect,
        rowsSelected: getSelectedRows()
    };

    return(  
    <Card>
        <CardContent>
            <MUIDataTable
                title={"Plants"}
                data={Plants}
                columns={columns}
                options={options}
            />
        </CardContent>
        </Card>
    );  
};
export default PlantTable;