import moment from 'moment';
    export const GetGraphData = (isshow, plant, state) =>  {
    const SelectedRecordValue = 10
    const IsCelcius = false
    let SelectedRecords = state.StartchGraphRecord.filter(f => f.plantID == plant.id && !f.deleted);

        
        
    
        let Obj = {
            isShowStarchGraphReport: isshow,
            isNull: false,
            RecordCount: SelectedRecordValue,
            DataSource: [],
            PlantData: null,
            StorageGraph: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Holding Tank Viscosity",
                isNull: false,
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Holding Tank Gel Temperature",
                isNull: false,
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Holding Tank Temperature",
                isNull: false,
                array: []
              }
            },
            DoserData: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser Viscosity",
                isNull: false,
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser Gel Temperature",
                isNull: false,
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser Temperature",
                isNull: false,
                array: []
              }
            },
            bBatchData: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Batch Viscosity",
                isNull: false,
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Batch Gel Temperature",
                isNull: false,
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Batch Finished Temperature",
                isNull: false,
                array: []
              }
            },
            DoserSFOne: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #1 Viscosity",
                isNull: false,
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #1 Gel Temperature",
                isNull: false,
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #1 Temperature",
                isNull: false,
                array: []
              }
            },
            DoserSFTwo: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #2  Viscosity",
                isNull: false,
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #2  Gel Temperature",
                isNull: false,
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser SF #2 Temperature",
                isNull: false,
                array: []
              }
            },
            DoserDb: {
              viscocity: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser DB Viscosity",
                isNull: false,
                array: []
              },
              gelTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser DB Gel Temperature",
                isNull: false,
                array: []
              },
              finTemp: {
                dateragnge: '',
                isCelcius: IsCelcius ? ' (°C)' : ' (°F)',
                title: "Doser DB Temperature",
                isNull: false,
                array: []
              }
            },
            Chart: {
              Type: "Line",
              Title: "Monthly Sales Data",
              XAxis: {
                Title: "Date",
                DataField: "Date"
              }
            }
          }
        if (SelectedRecords.length > 0 && isshow) {
          SelectedRecords.forEach(e => {
            if (e.location === 1) {
                if (Obj.StorageGraph.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.StorageGraph.gelTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.StorageGraph.gelTemp.array.unshift(temp)
                }
                if (Obj.StorageGraph.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.StorageGraph.viscocity.array.length +1,
                        xVal: e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                      }
                      Obj.StorageGraph.viscocity.array.unshift(temp)
                }
                if (Obj.StorageGraph.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.StorageGraph.finTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                      }
                    Obj.StorageGraph.finTemp.array.unshift(temp)
                }
                
            } 
            if (e.location === 0) {
                if (Obj.bBatchData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.bBatchData.viscocity.array.length +1,
                        xVal: e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.bBatchData.viscocity.array.unshift(temp)
                }
                if (Obj.bBatchData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.bBatchData.gelTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.bBatchData.gelTemp.array.unshift(temp)
                }
                if (Obj.bBatchData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.bBatchData.finTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.bBatchData.finTemp.array.unshift(temp)
                }
            } 
            if (e.location === 2 || e.location === 3 || e.location === 4) {
                if (Obj.DoserData.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserData.viscocity.array.length +1,
                        xVal: e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserData.viscocity.array.unshift(temp)
                }
                if (Obj.DoserData.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserData.gelTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserData.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserData.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserData.finTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserData.finTemp.array.unshift(temp)
                }
            } 
            if (e.location === 2) {
                if (Obj.DoserSFOne.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserSFOne.viscocity.array.length +1,
                        xVal: e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserSFOne.viscocity.array.unshift(temp)
                }
                if (Obj.DoserSFOne.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserSFOne.gelTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserSFOne.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserSFOne.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserSFOne.finTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserSFOne.finTemp.array.unshift(temp)
                }
              } 
              if (e.location === 3) {
                if (Obj.DoserSFTwo.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserSFTwo.viscocity.array.length +1,
                        xVal: e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserSFTwo.viscocity.array.unshift(temp)
                }
                if (Obj.DoserSFTwo.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserSFTwo.gelTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserSFTwo.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserSFTwo.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserSFTwo.finTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserSFTwo.finTemp.array.unshift(temp)
                }
            } 
            if (e.location === 4) {
                if (Obj.DoserDb.viscocity.array.length < SelectedRecordValue && e.type === 1) {
                    let temp = {
                        yVal: Obj.DoserDb.viscocity.array.length +1,
                        xVal: e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserDb.viscocity.array.unshift(temp)
                }
                if (Obj.DoserDb.gelTemp.array.length < SelectedRecordValue && e.type === 0) {
                    let temp = {
                        yVal: Obj.DoserDb.gelTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserDb.gelTemp.array.unshift(temp)
                }
                if (Obj.DoserDb.finTemp.array.length < SelectedRecordValue && e.type === 2) {
                    let temp = {
                        yVal: Obj.DoserDb.finTemp.array.length +1,
                        xVal: IsCelcius ? (((e.value - 32) * 5) / 9).toFixed(2) : e.value,
                        dateValue: moment(e.createdDate).format('MMM-DD'),
                        dateRValue: e.createdDate,
                    }
                    Obj.DoserDb.finTemp.array.unshift(temp)
                }
            } 
          });
          if (Obj.StorageGraph.gelTemp.array.length > 0) {
            Obj.StorageGraph.gelTemp.dateragnge =   moment(Obj.StorageGraph.gelTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.StorageGraph.gelTemp.array[Obj.StorageGraph.gelTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.StorageGraph.gelTemp.isNull = true
          }
          if (Obj.StorageGraph.viscocity.array.length > 0) {
            Obj.StorageGraph.viscocity.dateragnge =   moment(Obj.StorageGraph.viscocity.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.StorageGraph.viscocity.array[Obj.StorageGraph.viscocity.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.StorageGraph.viscocity.isNull = true
          }
          if (Obj.StorageGraph.finTemp.array.length > 0) {
            Obj.StorageGraph.finTemp.dateragnge =   moment(Obj.StorageGraph.finTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.StorageGraph.finTemp.array[Obj.StorageGraph.finTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.StorageGraph.finTemp.isNull = true
          }
          if (Obj.bBatchData.viscocity.array.length > 0) {
            Obj.bBatchData.viscocity.dateragnge =   moment(Obj.bBatchData.viscocity.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.bBatchData.viscocity.array[Obj.bBatchData.viscocity.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.bBatchData.viscocity.isNull = true
          }
          if (Obj.bBatchData.gelTemp.array.length > 0) {
            Obj.bBatchData.gelTemp.dateragnge =   moment(Obj.bBatchData.gelTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.bBatchData.gelTemp.array[Obj.bBatchData.gelTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.bBatchData.gelTemp.isNull = true
          }
          if (Obj.bBatchData.finTemp.array.length > 0) {
            Obj.bBatchData.finTemp.dateragnge =   moment(Obj.bBatchData.finTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.bBatchData.finTemp.array[Obj.bBatchData.finTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.bBatchData.finTemp.isNull = true
          }
          if (Obj.DoserData.viscocity.array.length > 0) {
            Obj.DoserData.viscocity.dateragnge =   moment(Obj.DoserData.viscocity.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserData.viscocity.array[Obj.DoserData.viscocity.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserData.viscocity.isNull = true
          }
          if (Obj.DoserData.gelTemp.array.length > 0) {
            Obj.DoserData.gelTemp.dateragnge =   moment(Obj.DoserData.gelTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserData.gelTemp.array[Obj.DoserData.gelTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserData.gelTemp.isNull = true
          }
          if (Obj.DoserData.finTemp.array.length > 0) {
            Obj.DoserData.finTemp.dateragnge =   moment(Obj.DoserData.finTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserData.finTemp.array[Obj.DoserData.finTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserData.finTemp.isNull = true
          }
          if (Obj.DoserSFOne.viscocity.array.length > 0) {
            Obj.DoserSFOne.viscocity.dateragnge =   moment(Obj.DoserSFOne.viscocity.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserSFOne.viscocity.array[Obj.DoserSFOne.viscocity.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserSFOne.viscocity.isNull = true
          }
          if (Obj.DoserSFOne.gelTemp.array.length > 0) {
            Obj.DoserSFOne.gelTemp.dateragnge =   moment(Obj.DoserSFOne.gelTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserSFOne.gelTemp.array[Obj.DoserSFOne.gelTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserSFOne.gelTemp.isNull = true
          }
          if (Obj.DoserSFOne.finTemp.array.length > 0) {
            Obj.DoserSFOne.finTemp.dateragnge =   moment(Obj.DoserSFOne.finTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserSFOne.finTemp.array[Obj.DoserSFOne.finTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserSFOne.finTemp.isNull = true
          }
          if (Obj.DoserSFTwo.viscocity.array.length > 0) {
            Obj.DoserSFTwo.viscocity.dateragnge =   moment(Obj.DoserSFTwo.viscocity.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserSFTwo.viscocity.array[Obj.DoserSFTwo.viscocity.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserSFTwo.viscocity.isNull = true
          }
          if (Obj.DoserSFTwo.gelTemp.array.length > 0) {
            Obj.DoserSFTwo.gelTemp.dateragnge =   moment(Obj.DoserSFTwo.gelTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserSFTwo.gelTemp.array[Obj.DoserSFTwo.gelTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserSFTwo.gelTemp.isNull = true
          }
          if (Obj.DoserSFTwo.finTemp.array.length > 0) {
            Obj.DoserSFTwo.finTemp.dateragnge =   moment(Obj.DoserSFTwo.finTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserSFTwo.finTemp.array[Obj.DoserSFTwo.finTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserSFTwo.finTemp.isNull = true
          }
          if (Obj.DoserDb.viscocity.array.length > 0) {
            Obj.DoserDb.viscocity.dateragnge =   moment(Obj.DoserDb.viscocity.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserDb.viscocity.array[Obj.DoserDb.viscocity.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserDb.viscocity.isNull = true
          }
          if (Obj.DoserDb.gelTemp.array.length > 0) {
            Obj.DoserDb.gelTemp.dateragnge =   moment(Obj.DoserDb.gelTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserDb.gelTemp.array[Obj.DoserDb.gelTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserDb.gelTemp.isNull = true
          }
          if (Obj.DoserDb.finTemp.array.length > 0) {
            Obj.DoserDb.finTemp.dateragnge =   moment(Obj.DoserDb.finTemp.array[0].dateRValue).format('D-MMM-YY') + ' - ' + moment(Obj.DoserDb.finTemp.array[Obj.DoserDb.finTemp.array.length - 1].dateRValue).format('D-MMM-YY')
          } else {
            Obj.DoserDb.finTemp.isNull = true
          }
        }

        Obj.StorageGraph.gelTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.StorageGraph.viscocity.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.StorageGraph.finTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.bBatchData.viscocity.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.bBatchData.gelTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.bBatchData.finTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserData.viscocity.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserData.gelTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserData.finTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserSFOne.viscocity.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserSFOne.gelTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserSFOne.finTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserSFTwo.viscocity.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserSFTwo.gelTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserSFTwo.finTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserDb.viscocity.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserDb.gelTemp.array.forEach((item,index)=>{item.yVal=index+1})
        Obj.DoserDb.finTemp.array.forEach((item,index)=>{item.yVal=index+1})

        

        console.log('ObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObjObj', Obj); 
        
    return Obj
}
