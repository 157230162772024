import Constants from '../../constants';
import React, { useEffect, useState } from 'react';
import { GetAttachmentSel } from '../../selectors/AttachmentSelectors';
import { PopulateFormulaStepsStrings, SelFormulaStepsByFormulaID } from '../../selectors/FormulaSelectors';
//MUI
const constants=Constants;
export const getFormulaValue = (DetailsObj, plant, state) =>  {
    const steps = PopulateFormulaStepsStrings(SelFormulaStepsByFormulaID(state,DetailsObj.id));
    let temp = GetAttachmentSel(state,DetailsObj.signatureImageID)
    const signature = temp ?  temp : null ;
    
    const dJSON ={Formula:DetailsObj, FormulaSteps:steps, Signature:signature };

    return dJSON

}